import A from "./1.jpg";
import B from "./2.jpg";
import C from "./3.jpg";
import D from "./4.jpg";
import E from "./5.jpg";
import F from "./6.jpg";
import G from "./7.jpg";
import H from "./8.jpg";
import I from "./9.jpg";
import J from "./10.jpg";
import K from "./11.jpg";
import L from "./12.jpg";
import M from "./13.jpg";
import N from "./14.jpg";
import O from "./15.jpg";
import P from "./16.jpg";
import Q from "./17.jpg";
import R from "./18.jpg";
import S from "./19.jpg";
import T from "./20.jpg";
import U from "./21.jpg";
import V from "./22.jpg";
import W from "./23.jpg";
import X from "./24.jpg";
import Y from "./25.jpg";
import Z from "./26.jpg";
import AA from "./27.jpg";
import AB from "./28.jpg";
import AC from "./29.jpg";
import AD from "./30.jpg";
import AE from "./31.jpg";
import AF from "./32.jpg";
import AG from "./33.jpg";
import AH from "./34.jpg";
import AI from "./35.jpg";


const galleryImages = [
    { src: A, alt: '1.jpg' },
    { src: B, alt: '2.jpg' },
    { src: C, alt: '3.jpg' },
    { src: D, alt: '4.jpg' },
    { src: E, alt: '5.jpg' },
    { src: F, alt: '6.jpg' },
    { src: G, alt: '7.jpg' },
    { src: H, alt: '8.jpg' },
    { src: I, alt: '9.jpg' },
    { src: J, alt: '10.jpg' },
    { src: K, alt: '11.jpg' },
    { src: L, alt: '12.jpg' },
    { src: M, alt: '13.jpg' },
    { src: N, alt: '14.jpg' },
    { src: O, alt: '15.jpg' },
    { src: P, alt: '16.jpg' },
    { src: Q, alt: '17.jpg' },
    { src: R, alt: '18.jpg' },
    { src: S, alt: '19.jpg' },
    { src: T, alt: '20.jpg' },
    { src: U, alt: '21.jpg' },
    { src: V, alt: '22.jpg' },
    { src: W, alt: '23.jpg' },
    { src: X, alt: '24.jpg' },
    { src: Y, alt: '25.jpg' },
    { src: Z, alt: '26.jpg' },
    { src: AA, alt: '27.jpg' },
    { src: AB, alt: '28.jpg' },
    { src: AC, alt: '29.jpg' },
    { src: AD, alt: '30.jpg' },
    { src: AE, alt: '31.jpg' },
    { src: AF, alt: '32.jpg' },
    { src: AG, alt: '33.jpg' },
    { src: AH, alt: '34.jpg' },
    { src: AI, alt: '35.jpg' },
    // Add more images as needed
  ];

  export default galleryImages;